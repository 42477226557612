const Link = {
  baseStyle: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '23px',
    fontStyle: 'normal',
    color: '#000000',
  },
  sizes: {},
  defaultProps: {},
};

export default Link;
