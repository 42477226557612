export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAIL = 'GET_TEAM_FAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USERS_TEAM = 'UPDATE_USERS_TEAM';
export const UPDATE_USER_VERIFICATION = 'UPDATE_USER_VERIFICATION';
export const UPDATE_USER_TEAM_NAME = 'UPDATE_USER_TEAM_NAME';
export const UPDATE_USER_TEAM_ORGANIZATION = 'UPDATE_USER_TEAM_ORGANIZATION';
